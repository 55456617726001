import axios from "axios";
import { createContext } from "react";
import { axiosETAGCache } from "axios-etag-cache";

import constructionResumeBuilderApi from "../../../resume_builder/frontend/apis";
import promptChartApi from "../../../core/prompt_chart/frontend/apis";
import translationApi from "../../../core/translation/frontend/apis";
import imageUploadApi from "../../../core/image_uploads/frontend/apis";
import config from "../../../core/frontend/components/config";
import axiosRetry from "axios-retry";

export const apiAccessTokenContext = createContext(null);

// Apply the axios ETAG interceptor
const axiosWithETAGCache = axiosETAGCache(axios);

export const api = {
  async home() {
    const response = await axiosWithETAGCache.get(`/`);
    return response.data;
  },
  async isAdmin() {
    const response = await axiosWithETAGCache.get(`/is-admin`);
    return response.data;
  },
  ...constructionResumeBuilderApi,
  ...promptChartApi,
  ...translationApi,
  ...imageUploadApi,
};

// Setup retries on API calls with backoff
axiosRetry(axios, {
  retries: 5,
  retryDelay: axiosRetry.exponentialDelay,
});

axios.defaults.baseURL = config.REACT_APP_BACKEND_API_URL;
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // Auth0.logout();
      return Promise.reject(error);
    }

    if (config.REACT_APP_DEBUG === "true") {
      alert(error.toString());
    } else {
      // Force reload the page. maybe this will help.
      // window.location.reload();
    }
    // Do something with response error
    return Promise.reject(error);
  }
);
