import "./LeftSideMenu.scss";
import * as React from "react";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Button from "@mui/material/Button";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ListItemIcon from "@mui/material/ListItemIcon";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useIsAuthenticated } from "../components/APIProvider";
import { useAuth0 } from "@auth0/auth0-react";
import LocalTranslatedText from "../../translation/frontend/components/LocalTranslatedText";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DescriptionIcon from "@mui/icons-material/Description";

export default function LeftSideMenu() {
  const { loginWithPopup } = useAuth0();
  const isAuthenticated = useIsAuthenticated();

  let menuList
    menuList = (
      <div className={"menu-pages-wrapper"} data-testid={"left-side-menu"}>
        <List>
          <ListItem>
            <ListItemText primary={"Admin Menu"} />
          </ListItem>
          <ListItem disablePadding color={"primary"}>
            <NavLink
              to={`/`}
              className={({ isActive, isPending }) => {
                return isActive ? "active" : isPending ? "pending" : "";
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<LocalTranslatedText language={"en"} text='Home' />}
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem disablePadding>
            <NavLink
              to={`/resumes`}
              className={({ isActive, isPending }) => {
                return isActive ? "active" : isPending ? "pending" : "";
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <LocalTranslatedText language={"en"} text='Resumes' />
                  }
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem disablePadding>
            <NavLink
              to={`/resume_prompt_configuration`}
              className={({ isActive, isPending }) => {
                return isActive ? "active" : isPending ? "pending" : "";
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <LocalTranslatedText
                      language={"en"}
                      text='Prompt Configuration'
                    />
                  }
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
          <ListItem disablePadding>
            <NavLink
              to={`/charts`}
              className={({ isActive, isPending }) => {
                return isActive ? "active" : isPending ? "pending" : "";
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AccountTreeIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <LocalTranslatedText language={"en"} text='Prompt Charts' />
                  }
                />
              </ListItemButton>
            </NavLink>
          </ListItem>
        </List>
      </div>
    );

  return <div className={"left-side-menu"}>{menuList}</div>;
}
