import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ErrorBoundary from "../components/ErrorBoundary";
import React, { useEffect } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "../components/SnackbarProvider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { ThemeProvider } from "@mui/material";
import { theme } from "../theme/theme";
import { APIProvider } from "../components/APIProvider";
import { PageWrapperWithMenu } from "../elements/PageWrapperWithMenu";
import { WaitForBackendAwakeWrapper } from "../components/WaitForBackendAwakeWrapper";
import config from "../../../core/frontend/components/config";
import { BackendPingWrapper } from "../components/BackendPingWrapper";
import "../components/ErrorReporting";
import { GoogleMapsProvider } from "../components/GoogleMapsProvider";
import { IsAdminProvider } from "../components/IsAdminProvider";

const LoadableWithoutOnboarding = ({ loader, loading }) => {
  const LoadableComponent = React.lazy(loader);
  return () => (
    <React.Suspense fallback={<PageWrapperWithMenu />}>
      <LoadableComponent />
    </React.Suspense>
  );
};

const Loadable = ({ loader, loading }) => {
  const LoadableComponent = React.lazy(loader);
  return () => (
    <React.Suspense fallback={<PageWrapperWithMenu />}>
      <LoadableComponent />
    </React.Suspense>
  );
};

const LoadableHomePage = LoadableWithoutOnboarding({
  loader: () => import("../pages/HomePage"),
  loading: () => <PageWrapperWithMenu />,
});

const LoadableResumeListPage = Loadable({
  loader: () =>
    import(
      "../../../resume_builder/frontend/resume_list/ResumeListPage"
    ),
  loading: () => <PageWrapperWithMenu />,
});

const LoadableResumeEditorPage = Loadable({
  loader: () =>
    import(
      "../../../resume_builder/frontend/resume_editor/ResumeEditorPage"
    ),
  loading: () => <PageWrapperWithMenu />,
});

const LoadableResumeStartPage = Loadable({
  loader: () =>
    import("../../../resume_builder/frontend/ResumeStartPage"),
  loading: () => <PageWrapperWithMenu />,
});

const LoadableResumePromptConfigurationPage = Loadable({
  loader: () =>
    import(
      "../../../resume_builder/frontend/EditResumePromptConfigurationPage"
    ),
  loading: () => <PageWrapperWithMenu />,
});

const LoadableTermsAndConditionsPage = Loadable({
  loader: () => import("../pages/TermsAndConditionsPage"),
  loading: () => <PageWrapperWithMenu />,
});

const LoadableChartListPage = Loadable({
  loader: () => import("../../prompt_chart/frontend/ChartListPage"),
  loading: () => <PageWrapperWithMenu />,
});

const LoadableChartEditPage = Loadable({
  loader: () => import("../../prompt_chart/frontend/ChartEditPage"),
  loading: () => <PageWrapperWithMenu />,
});

const router = createBrowserRouter(
  [
    {
      path: "",
      element: <LoadableHomePage />,
    },
    {
      path: "resumes",
      element: <LoadableResumeListPage />,
    },
    {
      path: "resume/start",
      element: <LoadableResumeStartPage />,
    },
    {
      path: "resume/:resumeId/*",
      element: <LoadableResumeEditorPage />,
    },
    {
      path: "resume_prompt_configuration",
      element: <LoadableResumePromptConfigurationPage />,
    },
    {
      path: "terms_and_conditions",
      element: <LoadableTermsAndConditionsPage />,
    },
    {
      path: "charts/",
      element: <LoadableChartListPage />,
    },
    {
      path: "charts/:chartId",
      element: <LoadableChartEditPage />,
    },
  ],
  {
    basename: config.REACT_APP_ROUTER_BASENAME,
  }
);

function MainFrontendApp() {
  // Check if the user has accessed the root URL, without the basename in the path. If so, we redirect
  // them to the appropriate URL with the basename.
  useEffect(() => {
    if (
      window.location.pathname === "/" &&
      config.REACT_APP_ROUTER_BASENAME !== "/"
    ) {
      window.location.pathname = config.REACT_APP_ROUTER_BASENAME;
    }
  }, []);

  // Decide what to show depending on whether we are authenticated or not
  let app = <RouterProvider router={router} />;

  // Wrap in provider which determines whether or not the user is an admin
  app = <IsAdminProvider>{app}</IsAdminProvider>;

  // Wrap in provider to wait for the backend server to be alive and awake
  app = <WaitForBackendAwakeWrapper>{app}</WaitForBackendAwakeWrapper>;

  // Wrap in provider for the API itself
  app = <APIProvider>{app}</APIProvider>;

  // Wrap in provider for the server ping process, to ensure backend server is alive.
  // Notably this is done before api provider to ensure it happens before login,
  // so that the server can be waking up while the user finishes their login process
  app = <BackendPingWrapper>{app}</BackendPingWrapper>;

  // Wrap in Snackbar provider
  app = <SnackbarProvider>{app}</SnackbarProvider>;

  if (config.REACT_APP_GOOGLE_MAPS_API_KEY) {
    // Wrap in a provider for the Google Maps client API
    app = <GoogleMapsProvider>{app}</GoogleMapsProvider>;
  }

  // Wrap in Theme provider
  app = <ThemeProvider theme={theme}>{app}</ThemeProvider>;

  // Wrap in Localization provider
  app = (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {app}
    </LocalizationProvider>
  );

  // Wrap in DnD provider
  app = <DndProvider backend={HTML5Backend}>{app}</DndProvider>;

  // Wrap in ErrorBoundary
  app = <ErrorBoundary>{app}</ErrorBoundary>;

  // Wrap in the auth0 provider
  app = (
    <Auth0Provider
      domain={config.REACT_APP_AUTH0_DOMAIN}
      clientId={config.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: new URL(
          config.REACT_APP_ROUTER_BASENAME,
          window.location.href
        ).href,
        scope: "read:current_user update:current_user_metadata email profile",
        audience: config.REACT_APP_AUTH0_AUDIENCE,
      }}
      cacheLocation='localstorage'
    >
      {app}
    </Auth0Provider>
  );

  return app;
}

export default MainFrontendApp;
